import React from 'react'
import videos from '../data/videos'
import Video from './video'

function Videos() {

  return (
    <div className="videos">
      <div className="videos__content">
        <div className="grid gap-y-10 lg:grid-cols-12 lg:gap-x-5">
          {videos.map((video, index) => (
            <div key={index} className="lg:col-span-6">
              <Video url={video.url} poster={video.poster} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Videos;
