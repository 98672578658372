import bigBuckBunny from '../images/BigBuckBunny.jpg'
import elephantsDream from '../images/ElephantsDream.jpg'
import forBiggerBlazes from '../images/ForBiggerBlazes.jpg'
import forBiggerEscapes from '../images/ForBiggerEscapes.jpg'
import forBiggerFun from '../images/ForBiggerFun.jpg'
import forBiggerJoyrides from '../images/ForBiggerJoyrides.jpg'
import forBiggerMeltdowns from '../images/ForBiggerMeltdowns.jpg'
import tearsOfSteel from '../images/TearsOfSteel.jpg'

const videos = [
  {
    url: 'https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    poster: bigBuckBunny,
  },
  {
    url: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
    poster: elephantsDream,
  },
  {
    url: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
    poster: forBiggerBlazes,
  },
  {
    url: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
    poster: forBiggerEscapes,
  },
  {
    url: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
    poster: forBiggerFun,
  },
  {
    url: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4',
    poster: forBiggerJoyrides,
  },
  {
    url: 'https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',
    poster: forBiggerMeltdowns,
  },
  {
    url: 'https://storage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4',
    poster: tearsOfSteel,
  }
];

export default videos
