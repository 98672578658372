import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Videos from '../components/videos'
import Layout from '../components/layout'
import Intro from '../components/intro'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Intro />
    <Videos />
  </Layout>
)

export default IndexPage
