import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'

function Video({ url, poster }) {
  const [buttonText, setButtonText] = useState('Copy Link')

  const onCopy = () =>  {
    setButtonText('Copied!')

    setTimeout(() => {
      setButtonText('Copy Link')
    }, 1200)
  }

  return (
    <div className="video">
      <div className="video__container">
        <video controls className="video__element" poster={poster}>
          <source src={url} type="video/mp4" />
        </video>
      </div>
      <CopyToClipboard text={url} onCopy={() => onCopy()}>
        <button type="button" className="video__button button">
          {buttonText}
        </button>
      </CopyToClipboard>
    </div>
  )
}

Video.propTypes = {
  url: PropTypes.string,
  poster: PropTypes.string,
}

export default Video
