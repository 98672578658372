import React from 'react'

function Intro() {

  return (
    <div class="intro">
      <div class="intro__content">
        <p class="intro__text">
          Free, open source video placeholders to use in your projects.
        </p>
      </div>
    </div>
  )
}

export default Intro
